import { Ellipsis, Image, Text, ZStack } from 'salad/primitives'
import { theme } from 'salad/stitches'
import appIllustration from './app-illustration.png'
import sparkles from './sparkles.png'

interface AppIllustrationProps {
  companyAliasName: string
}

export const AppIllustration = ({ companyAliasName }: AppIllustrationProps) => {
  return (
    <ZStack
      css={{
        position: 'relative',
        width: 'fit-content',
        maxWidth: 375,
        borderRadius: 10,
        boxShadow: theme.shadows['elevation-200'],
      }}
    >
      <Image src={appIllustration} alt="" width={375} height={196} />
      <Image
        src={sparkles}
        alt=""
        width={50}
        height={45}
        css={{
          display: 'none',
          '@bp1': {
            display: 'block',
          },
          position: 'absolute',
          bottom: 'calc(100% - 12px)',
          right: 'calc(100% - 12px)',
        }}
      />
      <Ellipsis
        css={{
          maxWidth: '100%',
          color: theme.colors['dark-blue'],
          placeSelf: 'start',
          paddingInline: '5.9%',
          marginBlockStart: '5.9%',
        }}
      >
        <Text
          css={{
            fontFamily: theme.fonts.champ,
          }}
        >
          apron.me/{companyAliasName}
        </Text>
      </Ellipsis>
    </ZStack>
  )
}
