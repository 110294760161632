import { useQuery } from '@tanstack/react-query'
import { useIsAuthenticated } from 'domains/auth/queries'
import { CacheKey } from 'kitchen/constants'
import type { FetchError, UseQueryOptions } from 'kitchen/types'
import { authorizedApi, publicApi } from 'kitchen/utils/api'
import { immutableRequestOptions } from 'kitchen/utils/fetch'
import { isObject } from 'kitchen/utils/helpers'
import { getFeatureFlags } from '../requests/features'
import type { CompanyId } from '../types/companies'
import type { FeatureFlag } from '../types/features'

export const useFeatureFlags = (
  companyId: CompanyId | undefined,
  options?: UseQueryOptions<FeatureFlag[]>
) => {
  const isAuthenticated = useIsAuthenticated()
  const fetch = isAuthenticated ? authorizedApi : publicApi

  return useQuery<FeatureFlag[], FetchError>(
    [CacheKey.FEATURES, companyId, isAuthenticated],
    ({ signal }) => getFeatureFlags(fetch, { companyId }, signal),
    {
      ...immutableRequestOptions,
      ...options,
    }
  )
}

export function useHasFeatureFlag(
  input: FeatureFlag[],
  options?: UseQueryOptions<FeatureFlag[]>
): boolean[]
export function useHasFeatureFlag(
  companyId: CompanyId | undefined,
  input: FeatureFlag[],
  options?: UseQueryOptions<FeatureFlag[]>
): boolean[]
export function useHasFeatureFlag(
  arg1?: CompanyId | FeatureFlag[] | undefined,
  arg2?: FeatureFlag[] | UseQueryOptions<FeatureFlag[]>,
  arg3?: UseQueryOptions<FeatureFlag[]>
): boolean[] {
  const companyId = typeof arg1 === 'string' ? arg1 : undefined
  const features = Array.isArray(arg1) ? arg1 : Array.isArray(arg2) ? arg2 : []
  const options = isObject(arg3)
    ? arg3
    : isObject(arg2) && !Array.isArray(arg2)
    ? arg2
    : {}
  const featureFlags =
    useFeatureFlags(companyId, { suspense: true, ...options }).data ?? []
  return features.map((flag) => featureFlags.includes(flag))
}
