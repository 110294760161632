import { FormattedMessage } from 'react-intl'
import { Status } from 'salad/components'
import * as Icons from 'salad/icons'

interface SomethingWentWrongErrorStatusProps {
  error?: unknown
}

export const SomethingWentWrongErrorStatus = ({
  error,
}: SomethingWentWrongErrorStatusProps) => {
  return (
    <Status.Root>
      <Status.Icon>
        <Icons.S64.Failure />
      </Status.Icon>
      <Status.Title>
        <FormattedMessage
          id="common.something-went-wrong"
          defaultMessage="Something went wrong"
        />
      </Status.Title>
      {process.env.BUILD_MODE === 'development' && (
        <Status.Description>{error?.toString()}</Status.Description>
      )}
    </Status.Root>
  )
}
