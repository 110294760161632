import * as Sentry from '@sentry/react'
import { FormattedMessage } from 'react-intl'
import { Status } from 'salad/components'
import { StatusLayout } from 'salad/layouts'
import { SomethingWentWrongErrorStatus } from '../something-went-wrong-error-status'

interface ErrorBoundaryProps {
  children: React.ReactNode
  onBack?: () => void
  onRetry?: () => void
}

export function ErrorBoundary({ children, onBack, onRetry }: ErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => (
        <StatusLayout.Root>
          <StatusLayout.Content>
            <SomethingWentWrongErrorStatus error={error} />
            {onBack && (
              <Status.Action
                onClick={() => {
                  resetError()
                  onBack()
                }}
              >
                <FormattedMessage id="common.go-back" defaultMessage="Go back" />
              </Status.Action>
            )}
            {onRetry && (
              <Status.Action
                onClick={() => {
                  resetError()
                  onRetry()
                }}
              >
                <FormattedMessage id="common.try-again" defaultMessage="Try again" />
              </Status.Action>
            )}
          </StatusLayout.Content>
        </StatusLayout.Root>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
