/* eslint @apron/sort-exports: error */

import { path } from 'kitchen/router'
import * as Segment from './segment'

export const CARD_CHALLENGE_STATUS = path(Segment.CARD_CHALLENGE, Segment.STATUS)
export const COMPANY_ALIAS = path(Segment.COMPANY_ALIAS_NAME)
export const COMPANY_ALIAS_PAYMENT = path(
  Segment.COMPANY_ALIAS_NAME,
  Segment.PAYMENT,
  Segment.PAYRUN_ID
)
export const INDEX = `/`
